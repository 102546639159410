import Axios from 'axios';

import { AuthorizeResponse, RefreshSessionResponse } from '@/interfaces/auth';
import { Country } from '@/interfaces/country';
import { CreateDomainRequest } from '@/interfaces/createDomainRequest';
import { CreateDomainResponse } from '@/interfaces/createDomainResponse';
import { CreateFarmRequest } from '@/interfaces/createFarmRequest';
import { CreateFarmResponse } from '@/interfaces/createFarmResponse';
import { CreateParcelRequest } from '@/interfaces/createParcelRequest';
import { CreateParcelResponse } from '@/interfaces/createParcelResponse';
import { CreateShapeRequest } from '@/interfaces/createShapeRequest';
import { CreateShapeResponse } from '@/interfaces/createShapeResponse';
import { CropType } from '@/interfaces/cropType';
import { Domain } from '@/interfaces/domain';
import { Entity } from '@/interfaces/entity';
import { Farm } from '@/interfaces/farm';
import { Parcel } from '@/interfaces/parcel';
import { ParcelByBbox } from '@/interfaces/parcelByBbox';
import { Shape } from '@/interfaces/shape';
import { UpdateParcelResponse } from '@/interfaces/updateParcelResponse';
import { UploadRequest } from '@/interfaces/uploadRequest';

class API {
  static _URL = process.env.VUE_APP_PRODUCT_MANAGER + '/api';

  static getCropTypes(): Promise<CropType[]> {
    return Axios.get(`${API._URL}/crop-types`, { withCredentials: true }).then((result) => result.data);
  }

  static getCountries(): Promise<Country[]> {
    return Axios.get(`${API._URL}/countries?valid=true`, { withCredentials: true }).then((result) => result.data);
  }

  static getOrganizations(countryId: string): Promise<Entity[]> {
    return Axios.get(`${API._URL}/organizations?country=${countryId}`, { withCredentials: true }).then(
      (result) => result.data
    );
  }

  static getUnits(orgId: string): Promise<Domain[]> {
    return Axios.get(`${API._URL}/units?org=${orgId}`, { withCredentials: true }).then((result) => result.data);
  }

  static getFarms(unitId: string): Promise<Farm[]> {
    return Axios.get(`${API._URL}/farms?unit=${unitId}`, { withCredentials: true }).then((result) => result.data);
  }

  static getParcels(farmId: string): Promise<Parcel[]> {
    return Axios.get(`${API._URL}/parcels?farm=${farmId}`, { withCredentials: true }).then((result) => result.data);
  }

  static getParcelsByBBox(
    llLong: number,
    llLat: number,
    urLong: number,
    urLat: number,
    date: string,
    orgs: string[]
  ): Promise<ParcelByBbox[]> {
    return Axios.get(`${API._URL}/parcel-by-bbox`, {
      params: {
        ll_long: llLong,
        ll_lat: llLat,
        ur_long: urLong,
        ur_lat: urLat,
        date: date,
        surveys: true,
        orgs: orgs.join(',')
      },
      withCredentials: true
    }).then((result) => result.data);
  }

  static getShape(shapeId: string): Promise<Shape> {
    return Axios.get(`${API._URL}/shape?id=${shapeId}`, { withCredentials: true }).then((result) => result.data);
  }

  static getShapesByIds(ids: string[]): Promise<Shape[]> {
    return Axios.post(`${API._URL}/get-shapes-by-ids`, { ids }, { withCredentials: true }).then(
      (result) => result.data
    );
  }

  static createUnit(payload: CreateDomainRequest): Promise<CreateDomainResponse> {
    return Axios.post(`${API._URL}/unit`, payload, { withCredentials: true }).then((result) => result.data);
  }

  static createFarm(payload: CreateFarmRequest): Promise<CreateFarmResponse> {
    return Axios.post(`${API._URL}/farm`, payload, { withCredentials: true }).then((result) => result.data);
  }

  static createParcel(payload: CreateParcelRequest): Promise<CreateParcelResponse> {
    return Axios.post(`${API._URL}/parcel`, payload, { withCredentials: true }).then((result) => result.data);
  }

  static createShape(payload: CreateShapeRequest): Promise<CreateShapeResponse> {
    return Axios.post(`${API._URL}/shape`, payload, { withCredentials: true }).then((result) => result.data);
  }

  static updateParcel(payload: Partial<Parcel>): Promise<UpdateParcelResponse> {
    return Axios.patch(`${API._URL}/parcel`, payload, { withCredentials: true }).then((result) => result.data);
  }

  static updateShape(payload: Shape): Promise<void> {
    return Axios.patch(`${API._URL}/shape`, payload, { withCredentials: true }).then((result) => result.data);
  }

  static authorize(role: string): Promise<AuthorizeResponse> {
    return Axios.get(`${API._URL}/authorize?role=${role}`, { withCredentials: true })
      .then((result) => result.data)
      .catch(() => false);
  }

  static upload(payload: UploadRequest): Promise<void> {
    return Axios.post(`${API._URL}/batch-upload`, payload, { withCredentials: true }).then((result) => result.data);
  }

  static transform(json: any): Promise<any> {
    return Axios.post(`${API._URL}/transform`, json, { withCredentials: true }).then((result) => result.data);
  }

  static refreshSession(): Promise<RefreshSessionResponse> {
    return Axios.get(`${API._URL}/refresh-session`, { withCredentials: true }).then((result) => result.data);
  }
}

export default API;
